<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        a) Based on the isotopic symbol shown below, determine the numbers of protons, electrons,
        and neutrons, as well as the mass number and charge of the isotope.
      </p>

      <v-simple-table class="mb-8">
        <thead>
          <tr>
            <th style="font-size: 14px; width: 15%">Symbol</th>
            <th style="font-size: 14px; width: 17%; text-align: center">Neutrons</th>
            <th style="font-size: 14px; width: 17%; text-align: center">Protons</th>
            <th style="font-size: 14px; width: 17%; text-align: center">Electrons</th>
            <th style="font-size: 14px; width: 17%; text-align: center">Mass Number</th>
            <th style="font-size: 14px; width: 17%; text-align: center">Charge</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <chemical-isotope
                :symbol="isotope1Element"
                :mass-number="isotope1MassNumber"
                :charge="isotope1Charge"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.neutronsA"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.protonsA"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.electronsA"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.massNumberA"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.chargeA"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p>
        b) Using the information provided below for the three isotopes, determine the missing
        information.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 14px; width: 15%">Element</th>
            <th style="font-size: 14px; width: 17%; text-align: center">Neutrons</th>
            <th style="font-size: 14px; width: 17%; text-align: center">Protons</th>
            <th style="font-size: 14px; width: 17%; text-align: center">Electrons</th>
            <th style="font-size: 14px; width: 17%; text-align: center">Mass Number</th>
            <th style="font-size: 14px; width: 17%; text-align: center">Charge</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <chemical-symbol-input v-model="inputs.elementB" :disabled="!allowEditing" />
            </td>
            <td style="text-align: center">
              <stemble-latex :content="'$\\text{' + isotope2Neutrons + '}$'" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.protonsB"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td style="text-align: center">
              <stemble-latex :content="'$\\text{' + isotope2Electrons + '}$'" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.massNumberB"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td style="text-align: center">
              <stemble-latex :content="'$\\text{' + isotope2Charge + '}$'" />
            </td>
          </tr>

          <tr>
            <td>
              <chemical-symbol-input v-model="inputs.elementC" :disabled="!allowEditing" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.neutronsC"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td style="text-align: center">
              <stemble-latex :content="'$\\text{' + isotope3Protons + '}$'" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.electronsC"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td style="text-align: center">
              <stemble-latex :content="'$\\text{' + isotope3MassNumber + '}$'" />
            </td>
            <td style="text-align: center">
              <stemble-latex :content="'$\\text{0}$'" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex class="pl-3" :content="'$\\text{' + isotope4Element + '}$'" />
            </td>
            <td style="text-align: center">
              <stemble-latex :content="'$\\text{' + isotope4Neutrons + '}$'" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.protonsD"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td style="text-align: center">
              <stemble-latex :content="'$\\text{' + isotope4Electrons + '}$'" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.massNumberD"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.chargeD"
                class="pb-4 pt-4 centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalIsotope from '../displayers/ChemicalIsotope';

export default {
  name: 'Question209',
  components: {
    ChemicalIsotope,
    ChemicalSymbolInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        neutronsA: null,
        protonsA: null,
        electronsA: null,
        massNumberA: null,
        chargeA: null,
        elementB: null,
        protonsB: null,
        massNumberB: null,
        elementC: null,
        neutronsC: null,
        electronsC: null,
        protonsD: null,
        massNumberD: null,
        chargeD: null,
      },
    };
  },
  computed: {
    chemicalIsotope1() {
      return this.taskState.getValueBySymbol('isotope1').content;
    },
    isotope1Element() {
      return this.chemicalIsotope1.symbol;
    },
    isotope1MassNumber() {
      return this.chemicalIsotope1.massNumber;
    },
    isotope1ChargeValue() {
      return this.taskState.getValueBySymbol('isotope1Charge').content;
    },
    isotope1Charge() {
      if (this.isotope1ChargeValue.value === -2) {
        return '2-';
      } else if (this.isotope1ChargeValue.value === -1) {
        return '-';
      } else if (this.isotope1ChargeValue.value === 0) {
        return '';
      } else if (this.isotope1ChargeValue.value === 1) {
        return '+';
      } else if (this.isotope1ChargeValue.value === 2) {
        return '2+';
      } else {
        return '';
      }
    },
    chemicalIsotope2() {
      return this.taskState.getValueBySymbol('isotope2').content;
    },
    isotope2MassNumber() {
      return this.chemicalIsotope2.massNumber;
    },
    isotope2Neutrons() {
      return this.chemicalIsotope2.neutrons;
    },
    isotope2ChargeValue() {
      return this.taskState.getValueBySymbol('isotope2Charge').content;
    },
    isotope2Charge() {
      if (this.isotope2ChargeValue.value === -2) {
        return '-2';
      } else if (this.isotope2ChargeValue.value === -1) {
        return '-1';
      } else {
        return '';
      }
    },
    isotope2Protons() {
      return this.chemicalIsotope2.atomicNumber;
    },
    isotope2Electrons() {
      return this.isotope2Protons - this.isotope2ChargeValue.value;
    },
    chemicalIsotope3() {
      return this.taskState.getValueBySymbol('isotope3').content;
    },
    isotope3Protons() {
      return this.chemicalIsotope3.atomicNumber;
    },
    isotope3MassNumber() {
      return this.chemicalIsotope3.massNumber;
    },
    chemicalIsotope4() {
      return this.taskState.getValueBySymbol('isotope4').content;
    },
    isotope4Element() {
      return this.chemicalIsotope4.symbol;
    },
    isotope4Neutrons() {
      return this.chemicalIsotope4.neutrons;
    },
    isotope4ChargeValue() {
      return this.taskState.getValueBySymbol('isotope4Charge').content;
    },
    isotope4Charge() {
      if (this.isotope4ChargeValue.value === 1) {
        return '+1';
      } else if (this.isotope4ChargeValue.value === 2) {
        return '+2';
      } else if (this.isotope4ChargeValue.value === 3) {
        return '+3';
      } else {
        return '';
      }
    },
    isotope4Electrons() {
      return Math.max(0, this.chemicalIsotope4.atomicNumber - this.isotope4ChargeValue.value);
    },
  },
};
</script>

<style>
.centered-input input {
  text-align: center;
}
</style>
